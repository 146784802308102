import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { ParameterForm } from './ParameterForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { ParameterUpdateInput, PartnerLab } from '../../../../graphql/generated/graphql.ts';
import { ParamType } from './ParamType';
import { LoadingIndicator } from '../../../../components/LoadingIndicator.tsx';

const UPDATE_PARAMETER_MUTATION = graphql(`
  mutation UpdateParameter($id: ID!, $input: ParameterUpdateInput!) {
    updateParameter(id: $id, input: $input) {
      id
    }
  }
`);

const PARAMETER_QUERY = graphql(`
  query Parameter($id: ID!) {
    labParameter(id: $id) {
      id
      longName
      shortName
      specimens {
        id
      }
      withParameters {
        id
      }
      withoutParameters {
        id
      }
      insuranceDefinitions {
        id
        insurance {
          id
        }
        diagnoses {
          id
        }
        periodicGroup {
          id
        }
        lg
      }
      specialRates {
        id
      }
      forms {
        id
      }
      deactivated
      doctorBilling
      patientBilling
      description
      billingDescription
      importantDescription
      edifactNumber
      link
      lgDoctorBilling
      storagePeriod
      evalHours
      priceDoctor
      priceLgDoctor
      pricePatient
      partnerLab
      synonyms
      acute
      volume
    }
  }
`);

export const UpdateParameterModal: React.FC<{
  parameterId: string | null;
  parameters: ParamType[];
  labId: string;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ parameterId, parameters, labId, onClose, onSuccess }) => {
  const [updateParameterMutation, { loading: updateLoading }] = useMutation(UPDATE_PARAMETER_MUTATION);
  const [form] = useForm<ParameterUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: ParameterUpdateInput) => {
    try {
      await updateParameterMutation({
        variables: {
          id: parameterId ?? '',
          input: {
            ...values,
            insuranceDefinitions: form.getFieldValue('insuranceDefinitions'),
            link: values.link.trim(),
            description: values.description.trim(),
          },
        },
      });

      message.success('Parameter wurde aktualisiert');
      onSuccess();
      onClose();
    } catch (e) {
      message.error('Parameter konnte nicht aktualisiert werden');
    }
  };

  const { data, loading } = useQuery(PARAMETER_QUERY, {
    variables: {
      id: parameterId ?? '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !parameterId,
  });

  useEffect(() => {
    form.setFieldsValue({
      longName: data?.labParameter?.longName ?? '',
      specimenIds: data?.labParameter?.specimens.map(s => s.id) ?? [],
      withParameterIds: data?.labParameter?.withParameters.map(p => p.id) ?? [],
      withoutParameterIds: data?.labParameter?.withoutParameters.map(p => p.id) ?? [],
      insuranceDefinitions:
        data?.labParameter?.insuranceDefinitions.map(it => ({
          insuranceId: it.insurance.id,
          diagnoseIds: it.diagnoses.map(d => d.id),
          lg: it.lg,
          periodicGroupId: it.periodicGroup?.id,
        })) ?? [],
      specialRateIds: data?.labParameter?.specialRates.map(i => i.id) ?? [],
      formIds: data?.labParameter?.forms.map(i => i.id) ?? [],
      deactivated: data?.labParameter?.deactivated ?? false,
      doctorBilling: data?.labParameter?.doctorBilling ?? false,
      patientBilling: data?.labParameter?.patientBilling ?? false,
      description: data?.labParameter?.description ?? '',
      billingDescription: data?.labParameter?.billingDescription ?? '',
      importantDescription: data?.labParameter?.importantDescription ?? '',
      edifactNumber: data?.labParameter?.edifactNumber ?? null,
      link: data?.labParameter?.link ?? '',
      lgDoctorBilling: data?.labParameter?.lgDoctorBilling ?? false,
      storagePeriod: data?.labParameter?.storagePeriod ?? -1,
      evalHours: data?.labParameter?.evalHours ?? -1,
      pricePatient: data?.labParameter?.pricePatient ?? null,
      priceDoctor: data?.labParameter?.priceDoctor ?? null,
      priceLgDoctor: data?.labParameter?.priceLgDoctor ?? null,
      partnerLab: data?.labParameter?.partnerLab ?? PartnerLab.NONE,
      synonyms: data?.labParameter?.synonyms ?? [],
      acute: data?.labParameter?.acute ?? false,
      volume: data?.labParameter?.volume ?? 0,
    });
  }, [form, data]);

  return (
    <Modal
      width={1024}
      title={`Parameter ${data?.labParameter?.shortName} bearbeiten`}
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!parameterId}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={updateLoading}
      destroyOnClose
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <ParameterForm
          form={form}
          onFinish={update}
          mode="update"
          labId={labId}
          parameters={parameters.filter(p => p.id !== parameterId)}
          edifactNumberValidator={edifactNumber => {
            if (!edifactNumber) {
              return true;
            }
            return !parameters.filter(p => p.id !== parameterId).some(p => p.edifactNumber === edifactNumber);
          }}
        />
      )}
    </Modal>
  );
};
