import { useLazyQuery } from '@apollo/client';
import { graphql } from '../graphql/generated';

const CHECK_PARAMETERS_QUERY = graphql(`
  query CheckParameters(
    $selectedParameters: [SelectedParameterInput!]!
    $patientData: PatientDataInput!
    $doctorId: ID!
  ) {
    checkParameters(selectedParameters: $selectedParameters, patientData: $patientData, doctorId: $doctorId) {
      selectedParameters {
        requestableParameter {
          id
          mainParameterId
          shortName
          longName
          withoutParameterIds
          acute
          volume
          billingInfos {
            billingType
            price
            text
            additionalText
            specialRateId
            diagnoseId
          }
          specimens {
            id
            classification
            name
            storagePeriod
            requiresLocalizations
            localizationInputEnabled
            predefinedLocalizations
            testTubes {
              id
              color
              name
              hasImage
              labelText
              labelText2
              link
              volume
            }
          }
          lab {
            id
            name
            shortName
            diagnoseRequired
          }
          link
          description
          billingDescription
          importantDescription
          evalHours
          synonyms
          pricePatient
          withParameterNames
          withoutParameterNames
        }
        billingInfo {
          additionalText
          billingType
          diagnoseId
          text
          price
          specialRateId
        }
      }
      unknownBillingParameters {
        id
        shortName
        longName
      }
      unavailableParameters
    }
  }
`);

export const useCheckParameters = () => {
  return useLazyQuery(CHECK_PARAMETERS_QUERY, { fetchPolicy: 'no-cache' });
};
