import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tabs,
  Transfer,
  Typography,
} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { SpecimenSelect } from '../../../../components/SpecimenSelect';
import { Parameter, ParameterSelect } from '../../../../components/ParameterSelect';
import { ParameterInsuranceEditor } from '../../../../components/ParameterInsuranceEditor';
import { translatePartnerLab } from '../../../../utils/enumHelpers';
import { EditableTagGroup } from '../../../../components/EditableTagGroup.tsx';
import { graphql } from '../../../../graphql/generated';
import { InsuranceDefinitionInput, PartnerLab } from '../../../../graphql/generated/graphql.ts';
import { useQuery } from '@apollo/client';
import { LoadingIndicator } from '../../../../components/LoadingIndicator.tsx';

const PARAMETER_FORM_QUERY = graphql(`
  query ParameterForm($labId: ID!) {
    insurances {
      id
      shortName
    }

    labSpecialRates(labId: $labId) {
      id
      name
      shortName
      code
    }

    labDiagnoses(labId: $labId) {
      id
      name
    }

    labSpecimens(labId: $labId) {
      id
      name
      classification
    }

    labForms(labId: $labId) {
      id
      name
    }

    labPeriodicGroups(labId: $labId) {
      id
      name
    }

    lab(id: $labId) {
      id
      pl1Name
      pl2Name
      pl3Name
    }
  }
`);

export interface InsuranceDefinitionAware {
  insuranceDefinitions: InsuranceDefinitionInput[];
}

interface Props<T> {
  form: FormInstance<T>;
  initialValues?: T;
  onFinish: (values: T) => void;
  mode: 'create' | 'update';
  parameters: Parameter[];
  labId: string;
  shortNameValidator?: (shortName: string | null | undefined) => boolean;
  edifactNumberValidator: (edifactNumber: number | null | undefined) => boolean;
}

export const ParameterForm = <T extends InsuranceDefinitionAware>({
  form,
  initialValues,
  onFinish,
  mode,
  parameters,
  labId,
  shortNameValidator,
  edifactNumberValidator,
}: Props<T>) => {
  const { data, loading } = useQuery(PARAMETER_FORM_QUERY, {
    variables: {
      labId: labId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !labId,
  });

  if (loading) {
    return <LoadingIndicator height="200px" />;
  }

  return (
    <Form<T> layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues}>
      <Tabs
        defaultActiveKey="grunddaten"
        size="small"
        items={[
          {
            key: 'grunddaten',
            label: 'Grunddaten',
            forceRender: true,
            children: (
              <>
                <Row gutter={[16, 0]}>
                  <Col span={6}>
                    <Form.Item
                      name="shortName"
                      label="Kurzbezeichnung"
                      rules={[
                        { type: 'string', required: mode === 'create', whitespace: true },
                        {
                          validator: (_, shortName) =>
                            !shortNameValidator || shortNameValidator(shortName)
                              ? Promise.resolve()
                              : Promise.reject('Kurzbezeichnung bereits vergeben'),
                        },
                      ]}
                    >
                      <Input
                        autoFocus={mode === 'create'}
                        disabled={mode === 'update'}
                        placeholder={mode === 'update' ? 'nicht änderbar' : ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="longName"
                      label="Langbezeichnung"
                      rules={[{ type: 'string', required: true, whitespace: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="edifactNumber"
                      label="Edifact-Nummer"
                      rules={[
                        { type: 'integer', required: false, min: 1, max: 9999999, message: 'Bitte Zahl angeben' },
                        {
                          validator: (_, editfactNumber) =>
                            edifactNumberValidator(editfactNumber)
                              ? Promise.resolve()
                              : Promise.reject('Edifact-Nummer bereits vergeben'),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        inputMode="numeric"
                        precision={0}
                        max={9999999}
                        min={1}
                        step={1}
                        autoFocus={mode === 'update'}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={18}>
                    <Form.Item name="synonyms" label="Synonyme" valuePropName="tags" rules={[{ type: 'array' }]}>
                      <EditableTagGroup addLabel="Neues Synonym" inputWidth="120px" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Form.Item name="deactivated" valuePropName="checked" noStyle>
                        <Checkbox>Deaktiviert</Checkbox>
                      </Form.Item>
                      <Form.Item name="acute" valuePropName="checked" noStyle>
                        <Checkbox>Akut anforderbar</Checkbox>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item name="specimenIds" label="Material" valuePropName="selected">
                      <SpecimenSelect style={{ width: '100%' }} multiple specimens={data?.labSpecimens ?? []} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="formIds" label="Formulare">
                      <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder="Suche nach Formularen"
                        optionFilterProp="search"
                        options={[...(data?.labForms ?? [])]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(it => ({
                            label: it.name,
                            value: it.id,
                            search: it.name,
                          }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item name="withParameterIds" label="Nur mit" valuePropName="selected">
                      <ParameterSelect style={{ width: '100%' }} multiple parameters={parameters} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="withoutParameterIds" label="Nicht mit" valuePropName="selected">
                      <ParameterSelect style={{ width: '100%' }} multiple parameters={parameters} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="partnerLab"
                      label="Partnerlabor"
                      rules={[{ type: 'enum', enum: Object.keys(PartnerLab) }]}
                    >
                      <Select>
                        {Object.keys(PartnerLab).map(it => {
                          return (
                            <Select.Option key={it} value={it}>
                              {translatePartnerLab(it, data?.lab)}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="volume"
                      label="Volumen"
                      rules={[{ type: 'integer', required: true, min: 0, max: 9999999, message: 'Bitte Zahl angeben' }]}
                    >
                      <InputNumber
                        inputMode="numeric"
                        required
                        min={0}
                        max={9999999}
                        step={1}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="storagePeriod"
                      label="Lagerdauer"
                      tooltip="-1 = Keine Lagerdauer von Material überschreiben"
                      rules={[{ type: 'integer', required: true, min: -1, max: 1000, message: 'Bitte Zahl angeben' }]}
                    >
                      <InputNumber
                        inputMode="numeric"
                        required
                        min={-1}
                        max={1000}
                        step={1}
                        style={{ width: '100%' }}
                        addonAfter="Stunden"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="evalHours"
                      label="Auswertungsdauer"
                      tooltip="-1 = Keine Auswertungsdauer"
                      rules={[{ type: 'integer', required: true, min: -1, max: 10000, message: 'Bitte Zahl angeben' }]}
                    >
                      <InputNumber
                        inputMode="numeric"
                        required
                        min={-1}
                        max={10000}
                        step={1}
                        style={{ width: '100%' }}
                        addonAfter="Stunden"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item name="doctorBilling" valuePropName="checked">
                      <Checkbox>Privat an Zuweiser</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="patientBilling" valuePropName="checked">
                      <Checkbox>Privat an Patient</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lgDoctorBilling" valuePropName="checked">
                      <Checkbox>LG - Privat an Zuweiser</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item
                      name="priceDoctor"
                      label="Preis Zuweiser"
                      rules={[
                        {
                          type: 'number',
                          required: false,
                          min: 0,
                          max: 9999.99,
                          message: 'Bitte validen Betrag angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        inputMode="decimal"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        max={9999.99}
                        step={0.01}
                        style={{ width: '100%' }}
                        addonAfter="€"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pricePatient"
                      label="Preis Patient"
                      rules={[
                        {
                          type: 'number',
                          required: false,
                          min: 0,
                          max: 9999.99,
                          message: 'Bitte validen Betrag angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        inputMode="decimal"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        max={9999.99}
                        step={0.01}
                        style={{ width: '100%' }}
                        addonAfter="€"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="priceLgDoctor"
                      label="Preis LG-Zuweiser"
                      rules={[
                        {
                          type: 'number',
                          required: false,
                          min: 0,
                          max: 9999.99,
                          message: 'Bitte validen Betrag angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        inputMode="decimal"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        max={9999.99}
                        step={0.01}
                        style={{ width: '100%' }}
                        addonAfter="€"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ),
          },
          {
            key: 'krankenkassen',
            label: 'Krankenkassen',
            forceRender: true,
            children: (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.insuranceDefinitions !== curValues.insuranceDefinitions
                }
              >
                {() => {
                  return (
                    <ParameterInsuranceEditor
                      periodicGroups={data?.labPeriodicGroups ?? []}
                      insurances={data?.insurances ?? []}
                      diagnoses={data?.labDiagnoses ?? []}
                      lg={false}
                    />
                  );
                }}
              </Form.Item>
            ),
          },
          {
            key: 'lg-krankenkassen',
            label: 'LG Krankenkassen',
            forceRender: true,
            children: (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.insuranceDefinitions !== curValues.insuranceDefinitions
                }
              >
                {() => (
                  <ParameterInsuranceEditor
                    periodicGroups={data?.labPeriodicGroups ?? []}
                    insurances={data?.insurances ?? []}
                    diagnoses={data?.labDiagnoses ?? []}
                    lg={true}
                  />
                )}
              </Form.Item>
            ),
          },
          {
            key: 'sondertarife',
            label: 'Sondertarife',
            forceRender: true,
            children: (
              <Form.Item name="specialRateIds" valuePropName="targetKeys">
                <Transfer
                  dataSource={[...(data?.labSpecialRates ?? [])].sort((a, b) => a.shortName.localeCompare(b.shortName))}
                  titles={['Nicht anforderbar', 'Anforderbar']}
                  render={sr => (
                    <>
                      {sr.shortName} <Typography.Text type="secondary">{sr.code}</Typography.Text>
                    </>
                  )}
                  showSearch
                  filterOption={(inputValue, specialRate) =>
                    specialRate.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                    specialRate.shortName.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  listStyle={{ height: '300px', width: '100%', overflow: 'hidden' }}
                  rowKey={sr => sr.id}
                />
              </Form.Item>
            ),
          },
          {
            key: 'informationen',
            label: 'Informationen',
            forceRender: true,
            children: (
              <>
                <Form.Item name="link" label="Link" rules={[{ type: 'url' }]}>
                  <Input addonBefore={<LinkOutlined />} />
                </Form.Item>
                <Form.Item
                  name="importantDescription"
                  label="Wichtige Informationen"
                  tooltip="Anzeige in Parameter-Info-Dialog, den ausgewählten Parametern und Zusammenfassung"
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item name="description" label="Beschreibung" tooltip="Anzeige in Parameter-Info-Dialog">
                  <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                  name="billingDescription"
                  label="Verrechnungsinformationen"
                  tooltip="Anzeige in Parameter-Info-Dialog und Verrechnungsdialog"
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
              </>
            ),
          },
        ]}
      />
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
