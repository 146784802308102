import { FC } from 'react';
import { List } from 'antd';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { WarningTwoTone } from '@ant-design/icons';
import { gold } from '@ant-design/colors';

export const ImportantInfos: FC<{
  selectedParams: SelectedParameter[];
  flipParamNames: boolean;
}> = ({ selectedParams, flipParamNames }) => {
  const paramsWithImportantInfos = selectedParams.filter(it => !!it.importantDescription);

  if (!paramsWithImportantInfos.length) {
    return null;
  }

  return (
    <div>
      <h3>Wichtige Informationen</h3>
      <List
        itemLayout="horizontal"
        dataSource={paramsWithImportantInfos}
        renderItem={param => (
          <List.Item>
            <List.Item.Meta
              avatar={<WarningTwoTone twoToneColor={gold[5]} style={{ fontSize: '16px' }} />}
              title={
                flipParamNames ? `${param.longName} - ${param.shortName}` : `${param.shortName} - ${param.longName}`
              }
              description={<div style={{ whiteSpace: 'pre-wrap' }}>{param?.importantDescription}</div>}
            />
          </List.Item>
        )}
      />
    </div>
  );
};
