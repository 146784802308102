import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { stripTypename } from '../utils/helpers';
import dayjs from 'dayjs';
import { useAppStore } from './store/useAppStore';
import { useShallow } from 'zustand/react/shallow';
import { FormFieldType, PatientData, RequestCopyDataFragment } from '../graphql/generated/graphql.ts';
import { getFragmentData, graphql } from '../graphql/generated';

const REQUEST_COPY_QUERY = graphql(`
  query RequestCopy($requestId: ID!, $currentPatientData: PatientDataInput) {
    requestCopy(requestId: $requestId, currentPatientData: $currentPatientData) {
      ...RequestCopyData
    }
  }
`);

const RequestCopyFragment = graphql(`
  fragment RequestCopyData on RequestCopy {
    labInfo
    diagnose
    acute
    sampledAt
    additionalData
    patientData {
      birthday
      city
      country
      email
      externalId
      firstName
      lastName
      gender
      zip
      svnr
      title
      street
      phone
      insuranceCode
      idCardAuthority
      idCardNumber
      idCardType
      insuranceCategory
      insuredPerson {
        phone
        street
        title
        svnr
        zip
        birthday
        gender
        firstName
        email
        country
        city
        lastName
      }
    }
    selectedParameters {
      requestableParameter {
        id
        mainParameterId
        shortName
        longName
        withoutParameterIds
        withoutParameterNames
        withParameterNames
        pricePatient
        billingInfos {
          billingType
          price
          text
          additionalText
          specialRateId
          diagnoseId
        }
        specimens {
          id
          classification
          name
          storagePeriod
          requiresLocalizations
          predefinedLocalizations
          localizationInputEnabled
          testTubes {
            id
            color
            name
            hasImage
            labelText
            labelText2
            link
            volume
          }
        }
        lab {
          id
          name
          shortName
          diagnoseRequired
        }
        link
        description
        billingDescription
        importantDescription
        evalHours
        synonyms
        acute
        volume
      }
      billingInfo {
        additionalText
        billingType
        diagnoseId
        text
        price
        specialRateId
      }
    }
    unknownBillingParameters {
      id
      shortName
      longName
    }
    unavailableParameters
    orderForms {
      id
      restore
      fields {
        id
        values
        type
        formFieldId
      }
    }
    localizations {
      paramId
      specimenId
      location
    }
  }
`);

export const useRequestCopy = (
  type: 'update' | 'new',
  updatePatientData: boolean,
  currentPatientData: PatientData | null,
  onContextUpdated: (data: RequestCopyDataFragment) => void
) => {
  const {
    executeRequestCopy,
    setExecuteRequestCopy,
    requestIdForUpdate,
    requestIdForNew,
    setParameterIdsWithUnknownBilling,
    setPatientData,
    setAdditionalData,
    setDiagnose,
    setLabInfo,
    setAcute,
    setFormData,
    setSampledAt,
    setParamQueue,
    setLocalizations,
  } = useAppStore(
    useShallow(state => ({
      executeRequestCopy: state.executeRequestCopy,
      setExecuteRequestCopy: state.setExecuteRequestCopy,
      requestIdForUpdate: state.requestIdForUpdate,
      requestIdForNew: state.requestIdForNew,
      setParameterIdsWithUnknownBilling: state.setParameterIdsWithUnknownBilling,
      setPatientData: state.setPatientData,
      setAdditionalData: state.setAdditionalData,
      setDiagnose: state.setDiagnose,
      setLabInfo: state.setLabInfo,
      setAcute: state.setAcute,
      setFormData: state.setFormData,
      setSampledAt: state.setSampledAt,
      setParamQueue: state.setParamQueue,
      setLocalizations: state.setLocalizations,
    }))
  );

  const { data } = useQuery(REQUEST_COPY_QUERY, {
    variables: {
      requestId: type === 'update' ? requestIdForUpdate : requestIdForNew,
      currentPatientData: currentPatientData,
    },
    skip: type === 'update' ? !requestIdForUpdate : !requestIdForNew,
    fetchPolicy: 'no-cache',
  });

  const requestCopy = getFragmentData(RequestCopyFragment, data?.requestCopy);

  useEffect(() => {
    if (requestCopy && executeRequestCopy) {
      setParamQueue(state => ({
        ...state.paramQueue,
        selectedParameters:
          requestCopy?.selectedParameters.map(it => ({
            ...it.requestableParameter,
            billingInfo: it.billingInfo,
          })) ?? [],
      }));

      setParameterIdsWithUnknownBilling(requestCopy.unknownBillingParameters.map(it => it.id));

      setFormData(
        requestCopy.orderForms
          .filter(it => (type === 'new' ? it.restore : true))
          .flatMap(it => it.fields)
          .filter(it => it.values.length)
          .reduce((result, field) => {
            switch (field.type) {
              case FormFieldType.DATE:
                result[field.formFieldId] = dayjs(field.values[0]);
                break;
              case FormFieldType.INPUT:
              case FormFieldType.OPTION_GROUP:
              case FormFieldType.SELECT:
                result[field.formFieldId] = field.values[0];
                break;
              default:
                result[field.formFieldId] = field.values;
            }
            return result;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }, {} as any)
      );

      if (updatePatientData) {
        const patientData = stripTypename(requestCopy.patientData);
        if (patientData.insuredPerson) {
          patientData.insuredPerson = stripTypename(patientData.insuredPerson);
        }
        setPatientData(patientData);
      }
      setDiagnose(requestCopy.diagnose);
      setLabInfo(requestCopy.labInfo);
      setAcute(requestCopy.acute);
      setAdditionalData(requestCopy.additionalData);
      setLocalizations(requestCopy.localizations);

      if (type === 'update') {
        setSampledAt(requestCopy.sampledAt || null);
      }

      onContextUpdated(requestCopy);
      setExecuteRequestCopy(false);
    }
  });

  return null;
};
